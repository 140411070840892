.CarouselArrows_carousel-arrow-container__opjMi {position: absolute;top: 40%;padding: 12px
}.CarouselArrows_carousel-arrow__aSyiY {display: flex;height: 40px;width: 40px;cursor: pointer;align-items: center;justify-content: center;border-radius: 9999px;border-width: 1px;--tw-border-opacity: 1;border-color: rgb(238 77 45 / var(--tw-border-opacity));--tw-bg-opacity: 1;background-color: rgb(255 255 255 / var(--tw-bg-opacity));--tw-text-opacity: 1;color: rgb(238 77 45 / var(--tw-text-opacity));--tw-drop-shadow: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));filter: var(--tw-filter);transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);transition-duration: 150ms
}@media (min-width: 960px) {.CarouselArrows_carousel-arrow__aSyiY {height: 100px
    }.CarouselArrows_carousel-arrow__aSyiY {width: 100px
    }
}
.ItemList_list__5r6fv::after,.ItemList_list__5r6fv::before{content:"";min-width:8px}
.GuessPrice_input-price__Ddobq{width:100%}.GuessPrice_input-price__Ddobq input{background-color:transparent;text-align:center;font-size:20px;line-height:24px;font-weight:500;caret-color:#ee4d2d;outline:2px solid transparent;outline-offset:2px}.GuessPrice_input-price__Ddobq.GuessPrice_animated__5TLoT{-webkit-animation:GuessPrice_beat__iH2d3 .15s infinite alternate;animation:GuessPrice_beat__iH2d3 .15s infinite alternate;transform-origin:center}@-webkit-keyframes GuessPrice_beat__iH2d3{to{transform:scale(1.4)}}@keyframes GuessPrice_beat__iH2d3{to{transform:scale(1.4)}}
.RemindCheckBox_container__hYZ32 {position: relative;margin-left: 12px;margin-right: 12px;display: flex;height: 20px;cursor: pointer;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;align-items: center;padding-left: 28px;font-size: 12px;line-height: 14px
}@media (min-width: 960px) {.RemindCheckBox_container__hYZ32 {height: 28px
    }.RemindCheckBox_container__hYZ32 {padding-left: 48px
    }.RemindCheckBox_container__hYZ32 {font-size: 20px;line-height: 24px
    }
}.RemindCheckBox_container__hYZ32 input {position: absolute;height: 0px;width: 0px;cursor: pointer;opacity: 0
}.RemindCheckBox_checkmark__o9qTd {position: absolute;top: 0px;left: 0px;height: 20px;width: 20px;border-radius: 2px;--tw-bg-opacity: 1;background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}@media (min-width: 960px) {.RemindCheckBox_checkmark__o9qTd {height: 28px
    }.RemindCheckBox_checkmark__o9qTd {width: 28px
    }.RemindCheckBox_checkmark__o9qTd {border-radius: 4px
    }
}.RemindCheckBox_container__hYZ32:hover input~.RemindCheckBox_checkmark__o9qTd {--tw-bg-opacity: 1;background-color: rgb(209 213 219 / var(--tw-bg-opacity))
}.RemindCheckBox_container__hYZ32 input:checked~.RemindCheckBox_checkmark__o9qTd {--tw-bg-opacity: 1;background-color: rgb(238 77 45 / var(--tw-bg-opacity))
}.RemindCheckBox_checkmark__o9qTd:after {position: absolute;display: none;--tw-content: "";content: var(--tw-content)
}.RemindCheckBox_container__hYZ32 input:checked~.RemindCheckBox_checkmark__o9qTd:after {display: block
}.RemindCheckBox_container__hYZ32 .RemindCheckBox_checkmark__o9qTd:after {top: 2px;left: 7px;height: 12px;width: 6px;--tw-rotate: 45deg;transform: var(--tw-transform);border-width: 0;border-right-width: 2px;border-bottom-width: 2px;--tw-border-opacity: 1;border-color: rgb(255 255 255 / var(--tw-border-opacity))
}@media (min-width: 960px) {.RemindCheckBox_container__hYZ32 .RemindCheckBox_checkmark__o9qTd:after {left: 9px
    }.RemindCheckBox_container__hYZ32 .RemindCheckBox_checkmark__o9qTd:after {height: 20px
    }.RemindCheckBox_container__hYZ32 .RemindCheckBox_checkmark__o9qTd:after {width: 10px
    }.RemindCheckBox_container__hYZ32 .RemindCheckBox_checkmark__o9qTd:after {border-right-width: 3px
    }.RemindCheckBox_container__hYZ32 .RemindCheckBox_checkmark__o9qTd:after {border-bottom-width: 3px
    }
}
