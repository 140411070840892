.input-price {
  @apply w-full;

  input {
    @apply caret-primary text-center text-title font-medium bg-transparent outline-none;
  }

  &.animated {
    animation: beat 0.15s infinite alternate;
    transform-origin: center;
  }
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.4);
  }
}
